import React from "react";
import { NavLink } from "react-router-dom";

const Solutions = () => {
  return (
    <div className="services-page">
      {/* Hero Section */}
      <section className="hero bg-dark py-5 text-center text-light">
        <div className="container">
          <h1 className="display-4 fw-bold">Our Services</h1>
          <p className="lead">
            Discover the full range of travel management solutions we offer, tailored to enhance your business travel experience.
          </p>
        </div>
      </section>

      {/* Services Blocks */}
      <section className="services-section py-5">
        <div className="container">
          <div className="row g-4">
            {/* Online Hotel Bookings */}
            <div className="col-md-6 col-lg-4">
              <div className="service-block bg-light text-center p-4 rounded shadow-sm h-100">
                <h2 className="h5 fw-bold text-primary">Online Hotel Bookings</h2>
                <p>Access a wide selection of hotels worldwide with competitive rates tailored for agents.</p>
                <NavLink to="/contact" className="btn btn-outline-primary btn-sm mt-3">Learn More</NavLink>
              </div>
            </div>

            {/* Tailor Made Group Programs */}
            <div className="col-md-6 col-lg-4">
              <div className="service-block bg-light text-center p-4 rounded shadow-sm h-100">
                <h2 className="h5 fw-bold text-primary">Tailor-Made Group Programs</h2>
                <p>Customized programs for groups, ensuring a unique travel experience for all participants.</p>
                <NavLink to="/contact" className="btn btn-outline-primary btn-sm mt-3">Discover Programs</NavLink>
              </div>
            </div>

            {/* Travel Packages */}
            <div className="col-md-6 col-lg-4">
              <div className="service-block bg-light text-center p-4 rounded shadow-sm h-100">
                <h2 className="h5 fw-bold text-primary">Exclusive Travel Packages</h2>
                <p>All-in-one travel packages designed to provide a seamless and memorable journey.</p>
                <NavLink to="/contact" className="btn btn-outline-primary btn-sm mt-3">View Packages</NavLink>
              </div>
            </div>

            {/* Additional Service Blocks */}
            <div className="col-md-6 col-lg-4">
              <div className="service-block bg-light text-center p-4 rounded shadow-sm h-100">
                <h2 className="h5 fw-bold text-primary">Daily Tours</h2>
                <p>Engaging day tours tailored to provide authentic experiences.</p>
                <NavLink to="/contact" className="btn btn-outline-primary btn-sm mt-3">Explore Tours</NavLink>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="service-block bg-light text-center p-4 rounded shadow-sm h-100">
                <h2 className="h5 fw-bold text-primary">Flight Tickets</h2>
                <p>Find affordable flight options for seamless travel planning.</p>
                <NavLink to="/contact" className="btn btn-outline-primary btn-sm mt-3">Book Now</NavLink>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="service-block bg-light text-center p-4 rounded shadow-sm h-100">
                <h2 className="h5 fw-bold text-primary">Yachting & Sailing</h2>
                <p>Exclusive yachting and sailing experiences for unforgettable travel.</p>
                <NavLink to="/contact" className="btn btn-outline-primary btn-sm mt-3">Set Sail</NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer CTA */}
      <section className="cta-section bg-dark text-light py-5">
        <div className="container text-center">
          <h2 className="fw-bold">Ready to Elevate Your Travel Experience?</h2>
          <p className="lead">Our dedicated team is here to support you 24/7. Connect with us today.</p>
          <NavLink to="/contact" className="btn btn-warning btn-lg">Contact Us</NavLink>
        </div>
      </section>
    </div>
  );
};

export default Solutions;
