import React from "react";
import { Footer, Navbar } from "../components";

const ContactPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-5">
        <h1 className="text-center display-4 text-primary fw-bold">
          Contact Adonnik
        </h1>
        <hr
          className="bg-primary mx-auto"
          style={{ width: "60px", height: "3px" }}
        />
        <p className="lead text-center text-muted">
          Looking to partner with us or have questions about our services? Fill
          out the form below, and a member of our team will be in touch shortly.
          For individual travel inquiries, please access your traveler portal.
        </p>

        <div className="row my-5">
          <div className="col-md-8 col-lg-6 col-sm-12 mx-auto">
            <form className="bg-light p-4 rounded shadow">
              <div className="form-group my-3">
                <label htmlFor="firstName" className="fw-bold">
                  First Name *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="Enter your first name"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="lastName" className="fw-bold">
                  Last Name *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Enter your last name"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="companyName" className="fw-bold">
                  Company Name *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="companyName"
                  placeholder="Enter your company name"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="jobTitle" className="fw-bold">
                  Job Title *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="jobTitle"
                  placeholder="Enter your job title"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="location" className="fw-bold">
                  Business Location *
                </label>
                <select className="form-control" id="location" required>
                  <option value="" disabled selected>
                    Select Location
                  </option>
                  <option value="US">United States</option>
                  <option value="UK">United Kingdom</option>
                  <option value="EU">Europe</option>
                  <option value="AS">Asia</option>
                  <option value="AU">Australia</option>
                </select>
              </div>
              <div className="form-group my-3">
                <label htmlFor="phone" className="fw-bold">
                  Business Phone Number
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  placeholder="Enter your phone number"
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="email" className="fw-bold">
                  Business Email *
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@company.com"
                  required
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="postalCode" className="fw-bold">
                  Business Postal Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="postalCode"
                  placeholder="Enter your postal code"
                />
              </div>
              <div className="form-group my-3">
                <label htmlFor="travelSpend" className="fw-bold">
                  Annual Travel Spend in USD *
                </label>
                <select className="form-control" id="travelSpend" required>
                  <option value="" disabled selected>
                    Select Range
                  </option>
                  <option value="0-50000">0 - 50,000</option>
                  <option value="50000-100000">50,000 - 100,000</option>
                  <option value="100000-500000">100,000 - 500,000</option>
                  <option value="500000-1000000">500,000 - 1,000,000</option>
                  <option value="1000000+">1,000,000+</option>
                </select>
              </div>
              <div className="form-group my-3">
                <label htmlFor="interest" className="fw-bold">
                  Area of Interest *
                </label>
                <select className="form-control" id="interest" required>
                  <option value="" disabled selected>
                    Select an option
                  </option>
                  <option value="travelManagement">
                    Corporate Travel Management
                  </option>
                  <option value="meetingsEvents">Meetings & Events</option>
                  <option value="technologySolutions">
                    Technology Solutions
                  </option>
                  <option value="consulting">Consulting Services</option>
                </select>
              </div>
              <div className="form-group my-3">
                <label htmlFor="message" className="fw-bold">
                  Message
                </label>
                <textarea
                  rows={5}
                  className="form-control"
                  id="message"
                  placeholder="Enter your message"
                ></textarea>
              </div>
              <div className="text-center">
                <button className="btn btn-primary px-5 py-2" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Contact Information */}
        <div className="row text-center mt-5">
          <div className="col-md-12">
            <h2 className="text-primary fw-bold">Contact Information</h2>
            <p className="text-muted">
              Prefer to reach out directly? Here's how you can connect with us:
            </p>
            <ul className="list-unstyled lead">
              <li>
                <strong>Address:</strong> 405 K St, Sacramento, CA 958114-3339
                , United States
              </li>
              <li>
                <strong>Phone:</strong> +1 916-514-4100
              </li>
              <li>
                <strong>Email:</strong>{" "}
                <a
                  href="mailto:info@adonnik.com"
                  className="text-decoration-none text-primary"
                >
                  info@adonnik.com
                </a>
              </li>
            </ul>
            <p className="text-muted">
              We look forward to connecting with you!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
