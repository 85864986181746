import React from "react";
import { Footer, Navbar } from "../components";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <>
      <Navbar />
      <div className="container my-5">
        <h1 className="text-center display-4 text-primary fw-bold">
          About Adonnik
        </h1>
        <hr
          className="bg-primary mx-auto"
          style={{ width: "60px", height: "3px" }}
        />

        {/* Introduction */}
        <section className="my-5 p-4 rounded shadow-sm bg-light text-center">
          <h2 className="fw-bold text-info mb-3">Our Mission</h2>
          <p className="lead text-muted">
            At Adonnik, our mission is to provide seamless, technology-driven
            travel solutions for businesses worldwide. We believe in
            transforming corporate travel through innovative, flexible services
            that meet the evolving needs of the travel industry.
          </p>
        </section>

        {/* Our Platform */}
        <section className="my-5 d-md-flex align-items-center bg-white rounded shadow p-4">
          <div className="col-md-6 p-3">
            <h2 className="fw-bold text-info">Advanced Travel Solutions</h2>
            <p>
              Adonnik's platform leverages advanced technologies to make
              corporate travel simpler, more efficient, and personalized. Our
              team focuses on bringing tailored experiences to each client,
              ensuring seamless travel from start to finish.
            </p>
          </div>
          <div className="col-md-6">
            <img
              src="/images/our-platform.jpg"
              alt="Our Platform"
              className="img-fluid rounded shadow-sm"
            />
          </div>
        </section>

        {/* Sustainability */}
        <section className="my-5 p-4 bg-light rounded shadow-sm text-center">
          <h2 className="fw-bold text-info">Commitment to Sustainability</h2>
          <p className="lead text-muted">
            We prioritize sustainable practices in every aspect of our services.
            Adonnik actively works to reduce carbon footprints through
            responsible travel options, efficient resource management, and
            partnerships with eco-friendly vendors.
          </p>
        </section>

        {/* Meetings & Events */}
        <section className="my-5 d-md-flex align-items-center bg-white rounded shadow p-4">
          <div className="col-md-6">
            <img
              src="/images/meetings-events.jpg"
              alt="Meetings & Events"
              className="img-fluid rounded shadow-sm"
            />
          </div>
          <div className="col-md-6 p-3">
            <h2 className="fw-bold text-info">Innovative Meetings & Events</h2>
            <p>
              Our Meetings & Events service offers comprehensive planning for
              virtual, hybrid, and in-person events. With Adonnik, your
              organization can host impactful events worldwide with minimal
              logistical challenges and maximum engagement.
            </p>
          </div>
        </section>

        {/* Key Facts */}
        <section className="my-5 p-4 bg-light rounded shadow-sm text-center">
          <h2 className="fw-bold text-info">Key Facts & Figures</h2>
          <p className="lead text-muted">
            With clients in over 150 countries, Adonnik is a leader in B2B
            travel solutions, offering state-of-the-art technology, expertise,
            and industry connections to enhance travel experiences for
            businesses everywhere.
          </p>
        </section>

        {/* Join Our Team */}
        <section className="my-5 text-center p-4 bg-white rounded shadow-sm">
          <h2 className="fw-bold text-info">Join Our Team</h2>
          <p className="lead text-muted">
            We are looking for dedicated professionals who share our passion for
            innovation and excellence in corporate travel. Explore career
            opportunities at Adonnik and help us shape the future of business
            travel.
          </p>
          <Link to="/careers" className="btn btn-primary mt-3">
            Explore Careers
          </Link>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
