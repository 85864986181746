import React from "react";
import { Link } from "react-router-dom";

const PartnershipSection = () => {
  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <h2 style={styles.heading}>Partner with Adonnik</h2>
        <p style={styles.subheading}>
          Join forces with Adonnik to expand your reach. Discover tailored
          partnership opportunities.
        </p>

        <div style={styles.row}>
          {partnerData.map((partner, index) => (
            <div key={index} style={styles.card}>
              <h5 style={styles.cardTitle}>{partner.title}</h5>
              <p style={styles.cardDescription}>{partner.description}</p>
              <Link to="/contact" style={styles.button}>
                Learn More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const partnerData = [
  {
    title: "Affiliate Partners",
    description:
      "Integrate Adonnik’s system to access exclusive booking rates. Contact us at: affiliate@adonnik.com",
  },
  {
    title: "Suppliers",
    description:
      "Become a preferred supplier in our network. Contact: suppliers@adonnik.com",
  },
  {
    title: "Hotels",
    description:
      "Enhance your hotel’s bookings and exposure with Adonnik. Reach us at: hotels@adonnik.com",
  },
  {
    title: "Technology Providers",
    description:
      "Integrate your solutions with Adonnik’s platform. Reach out to: tech@adonnik.com",
  },
  {
    title: "Marketing and PR",
    description:
      "Collaborate on joint campaigns for increased engagement. Contact: pr@adonnik.com",
  },
];

const styles = {
  section: {
    padding: "80px 0",
    backgroundColor: "#e9f0ff",
    textAlign: "center",
  },
  container: {
    maxWidth: "1100px",
    margin: "0 auto",
    padding: "0 20px",
  },
  heading: {
    fontSize: "2.5rem",
    color: "#1e3b57",
    fontWeight: "600",
    marginBottom: "20px",
  },
  subheading: {
    fontSize: "1.2rem",
    color: "#4e6186",
    marginBottom: "50px",
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    padding: "30px",
    margin: "15px",
    flex: "1 1 calc(33% - 30px)",
    maxWidth: "300px",
    color: "#333",
    textAlign: "left",
    transition: "transform 0.3s, box-shadow 0.3s",
  },
  cardTitle: {
    fontSize: "1.5rem",
    color: "#3f51b5",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  cardDescription: {
    fontSize: "1rem",
    color: "#556a8f",
    lineHeight: "1.6",
    marginBottom: "15px",
  },
  button: {
    display: "inline-block",
    padding: "8px 16px",
    backgroundColor: "#3f51b5",
    color: "#ffffff",
    borderRadius: "5px",
    textDecoration: "none",
    fontWeight: "bold",
  },
};

export default PartnershipSection;
