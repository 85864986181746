import React from "react";

const Advantages = () => {
  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <h2 style={styles.heading}>Why Partner with Adonnik</h2>
        <p style={styles.subheading}>
          See what sets us apart as leaders in corporate travel solutions.
        </p>
        <div style={styles.row}>
          <div style={styles.card}>
            <h5 style={styles.cardTitle}>Decade of Excellence</h5>
            <p style={styles.cardDescription}>
              A decade of success delivering seamless, reliable corporate travel
              services worldwide.
            </p>
          </div>
          <div style={styles.card}>
            <h5 style={styles.cardTitle}>Trusted by 10,000+ Clients</h5>
            <p style={styles.cardDescription}>
              Thousands choose Adonnik annually for worry-free corporate travel
              management.
            </p>
          </div>
          <div style={styles.card}>
            <h5 style={styles.cardTitle}>Exclusive Travel Deals</h5>
            <p style={styles.cardDescription}>
              Our partnerships with top providers mean access to exclusive rates
              for our clients.
            </p>
          </div>
          <div style={styles.card}>
            <h5 style={styles.cardTitle}>Global Network Coverage</h5>
            <p style={styles.cardDescription}>
              Enjoy seamless travel worldwide with our extensive coverage and
              partner network.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

const styles = {
  section: {
    padding: "80px 0",
    backgroundColor: "#f0f5ff",
    textAlign: "center",
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "0 20px",
  },
  heading: {
    fontSize: "2.5rem",
    color: "#1f3b5c",
    marginBottom: "10px",
    fontWeight: "600",
  },
  subheading: {
    fontSize: "1.1rem",
    color: "#4c6085",
    marginBottom: "50px",
  },
  row: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  card: {
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 6px 14px rgba(31, 59, 92, 0.1)",
    padding: "40px",
    margin: "15px",
    flex: "1 1 calc(25% - 30px)",
    maxWidth: "280px",
    transition: "transform 0.3s, box-shadow 0.3s",
    textAlign: "left",
    color: "#333",
  },
  cardTitle: {
    fontSize: "1.4rem",
    color: "#3f51b5",
    fontWeight: "bold",
  },
  cardDescription: {
    fontSize: "1rem",
    color: "#5f6d85",
    lineHeight: "1.6",
    marginTop: "10px",
  },
};

export default Advantages;
