// Footer.js
import React from "react";

const Footer = () => {
  return (
    <footer
      className="footer bg-gray text-white text-center py-5"
      style={{
        background: "linear-gradient(135deg, #2C3E50 0%, #1A5276 100%)",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase">About adonnik</h5>
            <p>
              At adonnik, we tailor unforgettable travel experiences with a
              focus on comfort and adventure.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase">Explore</h5>
            <ul className="list-unstyled">
              <li>
                <a
                  href="/"
                  className="text-decoration-none text-light hover-underline"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  className="text-decoration-none text-light hover-underline"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="/service"
                  className="text-decoration-none text-light hover-underline"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="text-decoration-none text-light hover-underline"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-4">
            <h5 className="fw-bold text-uppercase">Connect with Us</h5>
            <div>
              <a
                className="text-light fs-4 me-4 hover-zoom"
                href="https://twitter.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-twitter"></i>
              </a>
              <a
                className="text-light fs-4 hover-zoom"
                href="https://facebook.com/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-facebook"></i>
              </a>
            </div>
          </div>
        </div>
        <hr className="my-4 bg-light" />
        <p className="mb-0">© 2024 adonnik. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
