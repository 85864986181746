// Navbar.js
import React from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const Navbar = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark sticky-top shadow-lg py-3"
      style={{ backgroundColor: "#2C3E50" }}
    >
      <div className="container">
        <NavLink
          className="navbar-brand fw-bold fs-2 text-gradient"
          to="/"
          style={{ color: "#20C997" }}
        >
          adonnik
        </NavLink>
        <button
          className="navbar-toggler border-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav mx-auto text-center">
            <li className="nav-item">
              <NavLink
                className="nav-link fs-5 text-gradient mx-3"
                to="/"
                activeClassName="active"
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link fs-5 text-gradient mx-3"
                to="/service"
                activeClassName="active"
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link fs-5 text-gradient mx-3"
                to="/about"
                activeClassName="active"
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link fs-5 text-gradient mx-3"
                to="/contact"
                activeClassName="active"
              >
                Contact
              </NavLink>
            </li>
          </ul>
          <div className="btn-group">
            <NavLink to="/login" className="btn btn-info rounded-pill mx-1">
              Login
            </NavLink>
            <NavLink
              to="/register"
              className="btn btn-success rounded-pill mx-1"
            >
              Register
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
