import React from "react";
import { Footer, Navbar } from "../components";
import PartnershipSection from "../components/PartnershipSection";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Marquee from "react-fast-marquee";
import { toast } from "react-hot-toast";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <div className="container-fluid p-0">
        {/* Hero Section with Full-Width Slider */}
        <section
          className="hero-section text-center d-flex flex-column justify-content-center align-items-center"
          style={{
            backgroundImage: "url('/images/banner-new.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            color: "#f5f5f5",
            position: "relative",
          }}
        >
          <div
            className="overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(10, 34, 61, 0.8)",
            }}
          ></div>
          <div style={{ position: "relative", zIndex: 1, maxWidth: "700px" }}>
            <h1 className="display-4 fw-bold">
              Adonnik: Your Global B2B Travel Partner
            </h1>
            <p className="lead mt-3 mb-4">
              Expanding travel possibilities with custom solutions for agencies
              worldwide.
            </p>
            <Link to="/contact" className="btn btn-primary btn-lg">
              Join Us
            </Link>
          </div>
        </section>

        {/* About Section */}
        <section className="about-section py-5 bg-light text-center">
          <div className="container">
            <h2>About Adonnik</h2>
            <p className="lead">
              Adonnik provides a dynamic online platform for B2B travel bookings
              and services worldwide.
            </p>
          </div>
        </section>

        {/* Services Section */}
        <section className="services-section py-5">
          <div className="container text-center">
            <h2>Our Core Services</h2>
            <div className="row mt-4">
              <div className="col-md-4">
                <img
                  src="/images/hotel-icon.png"
                  alt="Hotels"
                  width="80"
                  className="mb-3"
                />
                <h4>Hotels</h4>
                <p>Access a wide selection of hotels at exclusive rates.</p>
              </div>
              <div className="col-md-4">
                <img
                  src="/images/flights-icon.png"
                  alt="Flights"
                  width="80"
                  className="mb-3"
                />
                <h4>Flights</h4>
                <p>Book flights with competitive rates worldwide.</p>
              </div>
              <div className="col-md-4">
                <img
                  src="/images/transfers-icon.png"
                  alt="Transfers"
                  width="80"
                  className="mb-3"
                />
                <h4>Transfers</h4>
                <p>Seamless transfers for a complete travel experience.</p>
              </div>
            </div>
          </div>
        </section>

        {/* Partners Marquee */}
        <section className="partners-marquee bg-light text-light py-4">
          <Marquee speed={50} pauseOnHover gradient={false}>
            <img
              src="/images/logo1.webp"
              alt="Partner 1"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo2.webp"
              alt="Partner 2"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo3.webp"
              alt="Partner 3"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo4.webp"
              alt="Partner 4"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo5.webp"
              alt="Partner 5"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo6.webp"
              alt="Partner 6"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo7.webp"
              alt="Partner 7"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo8.webp"
              alt="Partner 8"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo9.webp"
              alt="Partner 9"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo10.webp"
              alt="Partner 10"
              className="mx-4"
              width="120"
            />
            <img
              src="/images/logo11.webp"
              alt="Partner 11"
              className="mx-4"
              width="120"
            />
          </Marquee>
        </section>

        {/* Testimonials Section */}
        <section className="testimonials-section py-5 bg-primary text-white text-center">
          <h2>Testimonials</h2>
          <p className="lead mb-4">What our partners are saying about us</p>
          <blockquote className="blockquote">
            <p>
              “Adonnik has transformed our travel booking process with
              outstanding service and competitive pricing.”
            </p>
            <footer className="blockquote-footer text-light">
              CEO, Global Travel Corp
            </footer>
          </blockquote>
        </section>

        {/* Call to Action */}
        <section className="cta-section py-5 bg-light text-center">
          <h2>Ready to Partner with Adonnik?</h2>
          <p>
            Contact us today to learn more about our travel solutions tailored
            to your needs.
          </p>
          <Link to="/contact" className="btn btn-success btn-lg">
            Get Started
          </Link>
        </section>
      </div>
      <PartnershipSection />
      <Footer />
    </>
  );
};

export default HomePage;
